export default [
    {
        title: '项目管理',
        children: [
            {
                title: '项目',
                path: 'ProjectList',
            },
            {
                title: '模型交付单',
                path: 'DesList',
            },
            {
                title: '功能上线单',
                path: 'PrdVersionList',
            },
        ],
    },

    // 最新节点结构，2024.02.02
    {
        title: '模型管理',
        children: [
            {
                title: '模型视图',
                children: [
                    {
                        title: '模型总图',
                        path: 'SaList',
                    },
                    {
                        title: '信息结构',
                        path: 'IsList',
                    },
                    {
                        title: '业务地图',
                        path: 'ProjectBizMap',
                        checkPrjType: true,
                    },
                    {
                        title: '功能地图',
                        path: 'ProjectSfMap',
                        checkPrjType: true,
                    },
                    {
                        title: '界面地图',
                        path: 'ProjectUiMap',
                        checkPrjType: true,
                    },
                    {
                        title: '数据地图',
                        path: 'ProjectDataMap',
                        checkPrjType: true,
                    },
                    {
                        title: '接口地图',
                        path: 'ProjectInfMap',
                        checkPrjType: true,
                    },
                    {
                        title: '程序地图',
                        path: 'ProjectFcMap',
                        checkPrjType: true,
                    },
                    {
                        title: '部署地图',
                        path: 'ProjectDplMap',
                        checkPrjType: true,
                    },
                ]
            },

            {
                title: '模型制品',
                path: 'DpList',
            },

            {
                title: '业务模件',
                children: [
                    {
                        title: '业务结构',
                        path: 'BaList',
                    },
                    {
                        title: '业务流程',
                        path: 'BfList',
                    },
                    {
                        title: '业务对象',
                        path: 'BdList',
                    },
                    {
                        title: '业务服务',
                        path: 'SvrList',
                    },
                    {
                        title: '业务场景',
                        path: 'BfAlgList',
                    },
                    {
                        title: '业务术语',
                        path: 'BdTermList',
                    },
                    {
                        title: '业务规则',
                        path: 'BdRuleList',
                    },
                    {
                        title: '用户故事',
                        path: 'BdSummaryList',
                    },
                    {
                        title: '业务处理逻辑',
                        path: 'BdLogicList',
                    },
                    {
                        title: '业务能力',
                        path: 'BdAlgList',
                    },
                ]
            },

            {
                title: '功能模件',
                children: [
                    {
                        title: '功能结构',
                        path: 'FaList',
                    },
                    {
                        title: '系统功能',
                        path: 'SfList',
                    },
                    {
                        title: '集成用例',
                        path: 'UcList',
                    },
                ]
            },

            {
                title: '界面模件',
                children: [
                    {
                        title: '界面结构',
                        path: 'UaList',
                    },
                    {
                        title: '用户界面',
                        path: 'UiList',
                    },
                    {
                        title: '界面视图',
                        path: 'UvList',
                    },
                    {
                        title: '界面规则',
                        path: 'UiRuleList',
                    },
                    {
                        title: '界面能力',
                        path: 'UiAlgList',
                    },
                ]
            },

            // 数据架构待剔除
            {
                title: '数据模件',
                children: [
                    // {
                    //     title: '数据架构',
                    //     path: 'DaList',
                    // },
                    {
                        title: '数据库',
                        path: 'DbList',
                    },
                    {
                        title: '数据表',
                        children: [
                            {
                                title: '数据表',
                                path: 'DtList',
                            },
                            {
                                title: '表字段',
                                path: 'DfList',
                            },
                        ]
                    },
                    {
                        title: '数据视图',
                        path: 'DvList',
                    },
                    {
                        title: '标准字段',
                        children: [
                            {
                                title: '标准字段组',
                                path: 'DmPcdgList',
                            },
                            {
                                title: '标准字段',
                                path: 'DmPcdgDfList',
                            },
                            {
                                title: '引用标准的标准字段',
                                path: 'DmPcdgDfByCdgList',
                            },
                            {
                                title: '引用标准的表字段',
                                path: 'DmPcdgDfByDtList',
                            },
                        ]
                    },
                    {
                        title: 'CDT',
                        children: [
                            {
                                title: '定制数据类型',
                                path: 'CdtList',
                            },
                            {
                                title: '定制数据类型序列',
                                path: 'DmCdtSeqList',
                            },
                        ]
                    },
                ]
            },

            {
                title: '接口模件',
                children: [
                    {
                        title: '外联系统',
                        path: 'LsList',
                    },
                    {
                        title: '外联接口',
                        path: 'LsiList',
                    },
                ]
            },

            {
                title: '程序模件',
                children: [
                    {
                        title: '子系统',
                        path: 'SsList',
                    },
                    {
                        title: '程序能力',
                        path: 'FcList',
                    },
                    {
                        title: 'DTO',
                        path: 'DtoList',
                    },
                    {
                        title: '中间件',
                        path: 'ArchMiddlewareList',
                    },
                ]
            },
            {
                title: '部署模件',
                children: [
                    {
                        title: '物理机',
                        path: 'DepPsList',
                    },
                    {
                        title: '虚拟机',
                        path: 'DepVsList',
                    },
                ]
            },
            {
                title: '业务组件生成记录',
                path: 'BctRecord',
            },
        ]
    },

    {
        title: '资源库',
        code: 'TemplateLibrary',
        children: [
            {
                title: '业务组件库',
                code: 'BizComponentTemplateLibrary',
                path: 'BizComponentTemplateLibrary',
            },
            {
                title: '制品模板库',
                code: 'DesignProductTemplateLibrary',
                path: 'DesignProductTemplateLibrary',
            },
        ],
    },

    {
        title: '团队管理',
        code: 'TM',
        children: [
            {
                title: '个人号',
                path: 'UserList',
            },
            {
                title: '团队号',
                path: 'TeamList',
            },
            {
                title: '团队库',
                code: 'TmLibrary',
                children: [
                    {
                        title: '资源',
                        path: 'TeamBctList',
                    },
                    {
                        title: '界面视图模板',
                        path: 'TeamUvTemplateList',
                    },
                ],
            },
        ],
    },

    {
        title: '平台管理',
        code: 'SM',
        children: [
            {
                title: '系统配置',
                path: 'SmSet',
            },
            {
                title: '智能运维',
                path: 'AiOperation',
            },
        ],
    },
]
